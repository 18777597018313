<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteServicePanel(service_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="service_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_service') }}</div>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="list-group-item-accent-info p-0 pt-2 pb-2">
                        <div class="row m-0 align-items-center">
                            <div class="col-md p-0">
                                <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="service.name" :placeholder="translate('enter_serviceName')" />
                            </div>
                            <div class="col-md p-0">
                                <b-list-group-item disabled class="pl-1 pt-0 pb-0" :class="service.log_training ? 'bg-light' : ''">
                                    <q-checkbox :label="translate('log_training')" flat color="primary" emit-value id="service.log_training" :val="false" v-model="service.log_training" />
                                </b-list-group-item>
                                <b-list-group-item disabled class="pl-1 pt-0 pb-0" :class="service.requires_subscription ? 'bg-light' : ''">
                                    <q-checkbox :label="translate('require_subscription')" flat color="primary" emit-value id="requires_subscription_radio" :val="true" v-model="service.requires_subscription" />
                                </b-list-group-item>
                            </div>
                        </div>
                        <div class="row m-0 pl-3">
                            <div class="col-md p-0">
                                <small class="text-uppercase">{{ translate('service_type') }}</small>
                                <q-radio class="no-pointer-events" disabled color="primary" v-model="service.service_type" :label="service.service_type ? translate(service.service_type.replace('s_', '')) : null" :val="service.service_type" />
                            </div>
                            <div class="col-md p-0">
                                <small class="text-uppercase">{{ translate('providers_list') }}</small>
                                <q-radio class="no-pointer-events" disabled color="primary" :label="translate(service.providers_list)" v-model="service.providers_list" :val="service.providers_list" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div v-if="!service.requires_subscription" class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('basic_price')" @wheel="$event.target.blur()" type="number" id="basic_price" v-model.number="service.base_price" step="0.01" :placeholder="translate('enter_basicPrice')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                            <div class="col-md p-0" v-if="service.service_type !== 's_appointment'">
                                <q-input dense square color="primary" :label="translate('subscription_price')" @wheel="$event.target.blur()" type="number" id="subscription_price" v-model.number="service.subscription_price" step="0.01" :placeholder="translate('enter_subscriptionPrice')">
                                    <template v-if="currency" v-slot:append><small>{{ currency }}</small></template>
                                </q-input>
                            </div>
                        </div>
                        <div>
                            <q-input :label="translate('description')" :placeholder="translate('enter_description')" v-model="service.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                    </b-list-group-item>
                    <template>
                        <b-list-group-item class="list-group-item-accent-info p-0">
                            <q-item @click="showAddProvidersPanel(service_id)" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                                <q-item-label>
                                    <strong>{{ translate('associate_service_providers') }}</strong>
                                </q-item-label>
                                <q-space class="pr-2" />
                                <q-item-section side class="pr-0">
                                    <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                        <i class="fas fa-2x fas fa-people-carry"></i>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </template>
                    <b-list-group-item class="list-group-item-accent-info">
                        <custom-images :delete_route="delete_route" :entity_type="entity_type" :get_route="get_route" :post_route="post_route" :item_id="service_id" :item_images="service.images" />
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase small  cursor-pointer" @click="isHidden = !isHidden">
                        <i class="fas fa-history"></i> {{translate('price_history')}}
                    </b-list-group-item>
                    <b-list-group-item v-if="!isHidden && prices" class="list-group-item-accent-info">
                        <q-item class="list-item full-width" v-for="(price, i) in prices" :key="'price-'+i+'-'+price.id">
                            <q-item-section side>
                                <span><i class="fas fa-calendar"></i> <strong>{{ translate('valid_from') }}</strong></span>
                                {{ price.valid_from }}
                            </q-item-section>
                            <q-item-section side><strong>{{ translate('basic_price') }}</strong>{{price.base_price}}</q-item-section>
                            <q-item-section side><strong>{{ translate('subscription_price') }}</strong>{{price.subscription_price}}</q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editService">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import CustomImages from '../../../images/CustomImages'
import AddProvider from '../forms/AddIncludedProviders'
import DeleteService from '../forms/DeleteService'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'EditService',
    props: ['service_id', 'is_active', 'baseUrl', 'entity_type', 'patch_toggle_route'],
    components: {
        'custom-images': CustomImages,
        'toggle-active': ToggleActive,
    },
    computed: {
        post_route: function () {
            return baseUrl + 'services/images'
        },
        get_route: function () {
            return baseUrl + 'services'
        },
        delete_route: function () {
            return baseUrl + 'services/images'
        },
    },
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            service: {
                name: null,
                base_price: null,
                subscription_price: null,
                description: '',
            },
            prices: {},
            page: 1,
            msg: null,
            isHidden: true,
        }
    },
    created: function() {
        this.getService()
        this.getPrices()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showAddProvidersPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: AddProvider,
                props: {
                    service_id: id
                }
            })
        },
        showDeleteServicePanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteService,
                props: {
                    service_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getService: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'services/' + this.service_id, {
                    headers: headers
                })
                .then(response => {
                    this.service = response.data.item
                    this.service.images = this.service.images.map(image => {
                        image['image_name'] = baseUrl + image.image_name
                        return image
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getPrices: function () {
            var pricesURL = baseUrl +
                'services/prices?service_id=' + this.service_id +
                '&page=' + this.page +
                '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(pricesURL, {
                    headers: headers
                })
                .then(response => {
                    this.prices = response.data.prices
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editService: function () {
            var service_id = this.service_id
            var service_name = this.service.name

            if (this.service.requires_subscription) {
                this.service.base_price = this.service.subscription_price
            }
            if (this.service.service_type === 's_appointment') {
                this.service.subscription_price = this.service.base_price
            }

            var data = {
                'name': service_name,
                'base_price': this.service.base_price,
                'subscription_price': this.service.subscription_price,
                'description': this.service.description,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'services/' + service_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_service_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            service: { id: service_id, name: service_name }
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
