<template>
<div class="animated fadeIn">
    <services-worker v-if="showServicesWorker" :baseUrl="baseUrl"></services-worker>
    <services-customer v-if="showServicesCustomer" :baseUrl="baseUrl"></services-customer>
</div>
</template>

<script>
import _ from 'lodash'
import ServicesWorker from './ServicesWorker'
import ServicesCustomer from './ServicesCustomer';
export default {
    name: 'Services',
    components: {
        'services-worker': ServicesWorker,
        'services-customer': ServicesCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showServicesWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showServicesCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
