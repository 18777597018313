<template>
<div class="animated fadeIn">
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('provided_services')}}</h3>
        </div>
    </b-card>
    <offers-list :buy_panel_title="buy_panel_title" :get_route="get_route" :entity_type="entity_type" :appointment_disabled_buy_button_message="disabled_buy_button_message" :disabled_buy_button_message="translate('this_service_is_available_only_with_subscription')" :list="services" :baseUrl="baseUrl" :showDetailPanel="showDetailPanel" />
    <infinite-loading slot="append" @infinite="getServices" />
</div>
</template>

<script>
import OffersList from '../../template-parts/OffersListCustomer'
import _ from 'lodash'
import DetailService from './show/ServiceDetails'

export default {
    name: 'ServicesCustomer',
    components: {
        'offers-list': OffersList
    },
    computed: {
        entity_type: function () {
            return 'service'
        },
        get_route: function () {
            return baseUrl + 'services'
        },
        buy_panel_title: function () {
            return this.translate('buy_service')
        },
        disabled_buy_button_message: function () {
            return this.translate('appointment_service_type_cannot_be_purchased')
        },
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            services: [],
            service_active: 'active',
            service_inactive: '',
            service_inactive: '',
            order_by: '',
            page: 1,
            state: {},
        }
    },
    methods: {
        getServices: function ($state) {
            var serviceUrl = baseUrl +
                'services?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&service_status=' + this.service_active
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(serviceUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.services.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDetailPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DetailService,
                props: {
                    baseUrl: this.baseUrl,
                    service_id: id
                }
            })
        },
    }
}
</script>
