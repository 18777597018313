<template>
<div class="container">
    <br>
    <b-card>
        <div slot="header">
            <h3>
                <strong>{{panel_title}}</strong>
            </h3>
        </div>
        <p>{{panel_message}}</p>
        <b-row>
            <b-col>
                <b-btn variant="danger" @click="deleteServiceIncludedItem">{{translate('yes')}}</b-btn>
            </b-col>
            <b-col class="text-right">
                <b-button @click.prevent="closePanel" type="button" variant="light">{{translate('no')}}</b-button>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

<script>
import {eventBus} from "../../../../main"

export default {
    name: 'DeleteServiceIncludedItem',
    props: ['item_id', 'post_url', 'panel_title', 'panel_message', 'entity_type'],
    data() {
        return {
            msg: null,
        }
    },
    methods: {
        deleteServiceIncludedItem: function () {
            var url = baseUrl + this.post_url + '/' + this.item_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.delete(url, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
