<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('services')}}</h3>
        </div>
        <search-wrapper-card :type_filter="true" :active="service_active" :activeStatus="activeStatus" :inactive="service_inactive" :inactiveStatus="inactiveStatus" form_type="services">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternameservices" class="search-input" type="text" :placeholder="translate('find_service')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="type_filter">
                <q-item-section side class="pl-0 col-12 col-md-auto">
                    <q-checkbox @input="resetFilterByServiceType" v-model="filterByServiceType" :val="false" :size="$q.screen.lt.sm ? '1.75rem' : ''" color="primary" :label="translate('service_type')" />
                </q-item-section>
            </template>
            <template slot="add_button">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddServicePanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_service') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddServicePanel" />
            </template>
        </search-wrapper-card>
        <template v-if="filterByServiceType">
            <q-separator />
            <q-item class="p-0">
                <q-item-section>
                    <!-- dropdown -->
                    <q-select class="mt-1 mb-1" borderless @input="updateServiceType" :options="service_type_options" map-options :label="translate('service_type')" v-model="service_type" behavior="menu">
                        <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                <q-item-section side>
                                    <i :class="scope.opt.icon"></i>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label v-html="scope.opt.label" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <template v-slot:prepend>
                            <q-avatar class="shadow-2 mr-1" size="38px">
                                <i v-if="service_type !== null && service_type.value" :class="serviceTypeIcon(service_type.value)"></i>
                                <q-icon v-else name="fas fa-md fa-cogs" />
                            </q-avatar>
                        </template>
                    </q-select>
                </q-item-section>
            </q-item>
        </template>
    </b-card>
    <b-list-group class="list_wrapper-services">
        <draggable v-model="services" handle=".handle" group="services" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <q-item v-for="(service, i) in services" :key="'service-item-' + i + '-' + (i.id,service.id)" :id="service.id" class="list-item full-width draggable-item" :class="service.is_active ? 'service-active' : 'service-inactive'">
                    <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                        <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                            <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section class="p-0" side :class="$q.screen.lt.sm ? 'pl-3 pr-3' : 'pr-3'">
                        <i :class="serviceTypeIcon(service.service_type)">
                            <q-tooltip :offset="[10, 10]">{{ translate('service_type_' + service.service_type) }}</q-tooltip>
                        </i>
                    </q-item-section>
                    <q-item-section class="p-0" side>
                        <div class="image-thumbnail cursor-pointer handle" @click.prevent="showEditServicePanel(service.id, service.is_active)">
                            <img v-if="service.main_image_link" :src="service.main_image_link" />
                            <i v-else class="fa fa-photo fa-2x"></i>
                        </div>
                    </q-item-section>
                    <q-item-section class="full-height align-self-center pt-2 pb-2">
                        <strong class="text-muted">{{ service.name }}</strong>
                    </q-item-section>
                    <!-- [+] Desktop sections. -->
                    <template v-if="!$q.screen.lt.sm">
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="fas fa-people-carry" color="blue-6" @click="showAddProvidersPanel(service.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('associate_service_providers') }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="edit" color="primary" @click="showEditServicePanel(service.id, service.is_active)">
                                <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="delete" color="danger" @click="showDeleteServicePanel(service.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <toggle-active :is_active="service.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="service.id" :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                    <!-- [-] Desktop sections. -->
                </q-item>
            </transition-group>
        </draggable>
    </b-list-group>
    <infinite-loading :identifier="infServices" slot="append" @infinite="getServices" />
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import AddService from './forms/AddService'
import AddProvider from './forms/AddIncludedProviders'
import EditService from './forms/EditService'
import ServiceDetails from './show/ServiceDetails'
import DeleteService from './forms/DeleteService'
import BuyService from './forms/BuyService'
import draggable from 'vuedraggable'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'ServicesWorker',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        draggable,
    },
    props: ['baseUrl'],
    data: function () {
        return {
            filterByServiceType: false,
            service_type: null,
            infServices: 2020,
            drag: false,
            services: [],
            service_active: 'active',
            service_inactive: '',
            searchQuery: '',
            order_by: '',
            isTyping: false,
            page: 1,
            enableButon: false,
            state: {}
        }
    },
    mounted: function () {
        eventBus.$on('update_image_service', (image) => {
            this.updateMainImageLink(image)
        })
        eventBus.$on('toggle_active_service', (item) => {
            this.toggleActiveService(item)
        })
        eventBus.$on('delete_service', (id) => {
            this.deleteService(id)
        })
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
    },
    computed: {
        service_type_options: function () {
            // s_schedule, s_appointment, s_streaming
            return [{
                    value: 's_schedule',
                    label: this.translate('service_type_s_schedule'),
                    icon: 'fas fa-calendar-alt fa-lg'
                },
                {
                    value: 's_appointment',
                    label: this.translate('service_type_s_appointment'),
                    icon: 'fas fa-clock fa-lg'
                },
                {
                    value: 's_streaming',
                    label: this.translate('service_type_s_streaming'),
                    icon: 'fas fa-tv fa-lg'
                }
            ]
        },
        entity_type: function () {
            return 'service'
        },
        patch_toggle_route: function () {
            return baseUrl + 'services'
        },
        dragOptions() {
            return {
                animation: 0,
                group: "services",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        serviceTypeIcon: function (service_type) {
            let icons = {
                's_schedule': 'fas fa-lg fa-calendar-alt text-light-blue-4',
                's_appointment': 'fas fa-lg fa-clock text-light-blue-4',
                's_streaming': 'fas fa-lg fa-tv text-light-blue-4',
            }
            return icons[service_type]
        },
        resetFilterByServiceType: function (value) {
            if (!value) {
                this.service_type = null
                this.resetMainList()
            }
        },
        updateServiceType: function (element) {
            if (element) {
                this.service_type = element
            }
            this.resetMainList()
        },
        updateServiceName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.services.findIndex((obj => obj.id === item.service.id))
            // and update the element from the services array.
            this.services[objIndex]['name'] = item.service.name ? item.service.name : ''
        },
        toggleActiveService: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.services.findIndex((obj => obj.id === item.id))
                if (this.services[objIndex]) {
                    // And update is_active for the given item in list.
                    this.services[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.service_active === 'active' && this.service_inactive === '') {
                        this.deleteService(item.id)
                    }
                    if (item.is_active === 1 && this.service_active === '' && this.service_inactive === 'inactive') {
                        this.deleteService(item.id)
                    }
                }
            }
        },
        deleteService: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.services.findIndex((obj => obj.id === id))
            // and remove the element from the services array.
            this.services.splice(objIndex, 1)
        },
        updateMainImageLink: function (image) {
            if (image.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.services.findIndex((obj => obj.id === image.id))
                // And update main_image_link for the given item in list.
                if (!this.services[objIndex]['main_image_link']) {
                    this.services[objIndex]['main_image_link'] = ''
                }
                this.services[objIndex]['main_image_link'] = image.image_link
            }
        },
        resetMainList: function () {
            this.page = 1
            this.services = []
            if (this.services && this.services.length === 0) {
                // Force update using a new request.
                this.getServices(this.state)
            }
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var service_drag_id = event.moved.element.id
            var services = this.services
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var service_drop_id = services[drop_index].id

            var data = {
                "service_drag_id": service_drag_id,
                "service_drop_id": service_drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + 'services/position', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your services have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.service_active === 'active') {
                this.service_active = ''
            } else {
                this.service_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.service_inactive === 'inactive') {
                this.service_inactive = ''
            } else {
                this.service_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getServicesUrl: function () {
            var servicesUrl = baseUrl +
                'services?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                (this.searchQuery ? '&search=' + this.searchQuery : '') +
                (this.service_type && this.service_type.value ? '&service_type=' + this.service_type.value : '')

            if (this.service_active === 'active' && this.service_inactive === '') {
                servicesUrl += '&service_status=' + this.service_active
            }
            if (this.service_active === '' && this.service_inactive === 'inactive') {
                servicesUrl += '&service_status=' + this.service_inactive
            }

            return servicesUrl
        },
        getServices: function ($state) {
            var url = this.getServicesUrl()

            if (url) {
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                this.state = $state
                // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                let services = this.services
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        $state = this.state
                        if (response.data.items && response.data.items.length > 0) {
                            services.push(...response.data.items.map(item => {
                                if (item.main_image_link) {
                                    item['main_image_link'] = baseUrl + item.main_image_link
                                }
                                return item
                            }))
                        }
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                            this.services = services
                            $state.complete()
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        },
        showDeleteServicePanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteService,
                props: {
                    service_id: id
                }
            })
        },
        showAddServicePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddService,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showAddProvidersPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: AddProvider,
                props: {
                    service_id: id
                }
            })
        },
        showEditServicePanel: function (id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditService,
                props: {
                    service_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateServiceName(result)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group-item {
    cursor: move;
}

.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &.draggable-item:hover {
        border-style: dotted;
        border-color: rgba(0, 0, 0, .85);
    }
}

.service-active {
    border-left: 2px solid var(--q-color-primary) !important;
}

.service-inactive {
    border-left: 2px solid var(--q-color-dark) !important;
}
</style>
